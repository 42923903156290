import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useGetTrazabilidaQuery } from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import Loadingx from "../Loadingx/Loadingx";

const Linetime = ({ linetime, setLinetime, lineData, setLineData }) => {
  const { IDARCHXX, REGFECXX } = lineData;
  const { data, isLoading } = useGetTrazabilidaQuery({ IDARCHXX: IDARCHXX, anio: REGFECXX.split('-')[0] });
  return (
    <>
      <Modal show={linetime} onHide={() => {
        setLinetime(false)
        setLineData("")
      }}>
        <Modal.Header style={{ "backgroundColor": "#000000", "color": "white" }}>
          TimeLine
          <Button className="btn-close btn-close-white" aria-label="Close"
            onClick={() => {
              setLinetime(false)
              setLineData("")
            }} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            {isLoading ? (
              <Loadingx
                width={50}
                height={50}
              />
            ) :
              data?.data.length > 0 ? (
                data.data.map((item, key) => {
                  return (
                    <Row key={key}>
                      <Col className="col-2">
                        <img
                          src={`${process.env.REACT_APP_VISOR_WEB}/images/picture_defaul.png`}
                          className="rounded"
                          width={35}
                          alt="Nav Icon Users"
                        />
                      </Col>
                      <Col className="col-10 ">
                        <Row>
                          {item.USRNOMXX}
                        </Row>
                        <Row>
                          <Col>
                            <strong>{item.ESTADOXX} :</strong> {item.REGFECXX} / {item.REGHORXX}
                          </Col>
                        </Row>
                        <hr></hr>
                      </Col>
                    </Row>
                  )
                })
              ) : (
                <Col className="d-flex justify-content-center">
                  <strong>SIN REGISTROS</strong>
                </Col>
              )
            }
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Linetime;
