import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import QueryString from "qs";

const prepareQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CIMA_ENDPOINT,
  mode: "cors",
  timeout: process.env.REACT_APP_CIMA_TIMEOUT,
  credentials: "include",
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().cimaxxxx.token;
    Headers.set("Accept", "application/json");
    Headers.set("Content-Type", "application/json");
    if (token) {
      Headers.set("Authorization", `Bearer ${token}`);
    }
    return Headers;
  },
  paramsSerializer: (params) => {
    return QueryString.stringify(params);
  },
});

const baseQuery = async (args, api, extraOptions) => {
  let getData = await prepareQuery(args, api, extraOptions);
  return getData;
};

export const cimaxxxxApiSlice = createApi({
  reducerPath: "cimaxxxxApiSlice",
  baseQuery,
  endpoints: (builder) => ({
    loginCima: builder.mutation({
      query: (credentials) => ({
        url: "/loginCima",
        method: "POST",
        body: {
          USRIDXXX: credentials.USRIDXXX,
          USRPASXX: credentials.USRIDXXX,
          SISTEMAX: "CIMA",
          EXTERNAL: true,
        },
        credentials: "include",
        headers: {
          Modulecima: "usuarios",
        },
      }),
    }),
    // endpoint para consultar la Funcion Transportadora
    getTransporter: builder.query({
      query: (filter) => ({
        url: `/Doctraxx/Saldoctr/getTransportadorInt?filter=${filter}`,
        method: "GET",
        headers: {
          Modulecima: "Doctraxx",
        },
      }),
    }),
    // endpoint para consultar clientes en base de datos cima
    getCimaClient: builder.query({
      query: (filter) => ({
        url: `Modclixx/Fichacli?page=1&per_page=15&sorts=CIPO0024.REGESTXX&${filter}`,
        method: "GET",
        headers: {
          Modulecima: "Modclixx",
        },
      }),
    }),
    // endpoint para consultar proveedores en base de datos cima
    getCimaProvider: builder.query({
      query: (filter) => ({
        url: `Modclixx/Fichprov?page=1&per_page=15&sorts=CIPO0050.REGESTXX&${filter}`,
        method: "GET",
        headers: {
          Modulecima: "Modclixx",
        },
      }),
    }),
    // endpoint para consultar Direccion del Proveedor en base de datos cima
    getCimaDirectorProvider: builder.query({
      query: (filter) => ({
        url: `Modclixx/Fichprov/getSupplierAddresses?page=1&per_page=15&sorts=CIPO0029.REGESTXX&${filter}`,
        method: "GET",
        headers: {
          Modulecima: "Modclixx",
        },
      }),
    }),
    getNumContrato: builder.query({
      query: (filter) => ({
        url: `Modclixx/Fichprov/getNumContrato?page=1&per_page=15&sorts=CIPO0157.REGESTXX&${filter}`,
        method: "GET",
        headers: {
          Modulecima: "Modclixx",
        },
      }),
    }),
  }),
});

export const {
  useLoginCimaMutation,
  useLazyGetCimaClientQuery,
  useLazyGetNumContratoQuery,
  useLazyGetTransporterQuery,
  useLazyGetCimaProviderQuery,
  useLazyGetCimaDirectorProviderQuery
} = cimaxxxxApiSlice;
