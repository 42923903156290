import { apiSlice } from "../apiSlice";

export const cretipxxApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createType: builder.mutation({
      query: (parameters) => ({
        url: `tipos`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreaSerieType: builder.mutation({
      query: (parameters) => ({
        url: `serieportipos`,
        method: "POST",
        body: parameters,
      }),
    }),
    createCompanyAreaSerieTypeParents: builder.mutation({
      query: (parameters) => ({
        url: `padreportipos`,
        method: "POST",
        body: parameters,
      }),
    }),
    getTypeParent: builder.query({
      query: (parameters) => ({
        url: `/getTypeParent`,
        method: "GET",
        params: `filters[EMPNITXX]=${parameters.EMPNITXX}&filters[AREAIDXX]=${parameters.AREAIDXX}&filters[SERIEIDX]=${parameters.SERIEIDX}`
      }),
    }),
    getTypeParentOptions: builder.mutation({
      query: (parameters) => ({
        url: `/getTypeParent`,
        method: "GET",
        params: `filters[EMPNITXX]=${parameters.EMPNITXX}&filters[AREAIDXX]=${parameters.AREAIDXX}&filters[SERIEIDX]=${parameters.SERIEIDX}&filters[BANAPLPA]=${parameters.BANAPLPA}`
      }),
    }),
    getParentsByTypes: builder.mutation({
      query: (parameters) => ({
        url: `/padreportipos/${parameters.EMPNITXX}/${parameters.AREAIDXX}/${parameters.SERIEIDX}/${parameters.TIPOIDXX}`,
        method: "GET",
      }),
    }),
  }),
});

export const { 
  useCreateTypeMutation, 
  useCreateCompanyAreaSerieTypeMutation, 
  useCreateCompanyAreaSerieTypeParentsMutation, 
  useLazyGetTypeParentQuery, 
  useGetTypeParentOptionsMutation,
  useGetParentsByTypesMutation 
} = cretipxxApiSlice;
