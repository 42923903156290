import { apiSlice } from "../apiSlice";

export const packagexApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSeriesxFilters: builder.mutation({
      query: (filters) => ({
        url: `/getSeriesxFilters?`+filters,
        method: "GET"
      }),
    }),
  }),
});

export const { useGetSeriesxFiltersMutation } = packagexApiSlice;
