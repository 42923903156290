class Parent {
  TYPEOPTS;
  METAOPTS;
  EMPNITPA;
  AREAIDPA;
  SERIEIPA;
  TIPOIDPA;
  METIDXXX;
  constructor(EMPNITXX, AREAIDPA) {
    this.TYPEOPTS = [];
    this.METAOPTS = [];
    this.EMPNITPA = EMPNITXX;
    this.AREAIDPA = AREAIDPA;
    this.SERIEIPA = "";
    this.TIPOIDPA = "";
    this.METIDXXX = "";
  }
}

export default Parent;