import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../slices/apiSlice";
import userReducer from "../slices/authSlice/authSlice";
import errorSlice from "../slices/errorSlice/errorSlice";
import filterSlice from "../slices/filterSlice/filterSlice";
import queueSlice from "../slices/queueSlice/queueSlice";
import confirmxxSlice from "../slices/confirmxxSlice/confirmxxSlice";
import splitpdfSlice from "../slices/splitpdfSlice/splitpdfSlice";

//Redux persist
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
// eslint-disable-next-line
import storageIndexedDB from "redux-persist-indexeddb-storage";
import thunk from "redux-thunk";
import { cimaxxxxApiSlice } from "../slices/cimaxxxx/cimaxxxxApiSlice";
import trkgfileSlice from "../slices/Trkgfile/trkgfileSlice";
import cimaxxxSlice from "../slices/cimaxxxx/cimaxxxxSlice";

const persistConfig = {
  key: "auth",
  storage: storageSession,
};

const persistQueue = {
  key: "queue",
  storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedQueueReducer = persistReducer(persistQueue, queueSlice);

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [cimaxxxxApiSlice.reducerPath]: cimaxxxxApiSlice.reducer,
    persistedReducer,
    persistedQueueReducer,
    error: errorSlice,
    filter: filterSlice,
    confirm: confirmxxSlice,
    splitpdf: splitpdfSlice,
    cimaxxxx: cimaxxxSlice,
    trkgfile: trkgfileSlice,
  },
  devTools: process.env.REACT_APP_GENIO_DEBUG,
  middleware: [thunk, apiSlice.middleware, cimaxxxxApiSlice.middleware],
});

export const persistor = persistStore(store);
