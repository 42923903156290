import React from "react";

import Toast from "react-bootstrap/Toast";
import { Button } from "react-bootstrap";

const Confsald = ({ onConfirm }) => {
  return (
    <Toast className="p-3" position="top-end" showCloseButton>
      <Toast.Body style={{ textAlign: "center" }}>
        <div>El documento de transporte ya existe</div>
        <div>¿Desea crear un nuevo saldo?</div><br />
        <div>
          <Button variant="success" onClick={(event) => {
            onConfirm("SI", event)
          }}>SI</Button>{"   "}
          <Button variant="success" onClick={(event) => {
            onConfirm("NO", event)
          }}>NO</Button>
        </div>
      </Toast.Body>
    </Toast>
  );
};

export default Confsald;
