import { useEffect } from "react";

import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ProcessPage from "./pages/ProcessPage";
import AdminPage from "./pages/AdminPage";
import PercargoPage from "./pages/PercargoPage";
import PerUserPage from "./pages/PerUserPage";
import WindowPdfViewer from './components/Selparxx/WindowPdfViewer';

//para builderar electron
import { HashRouter, Navigate, Routes, Route } from "react-router-dom";
//para desarrollo usar BrowserRouter en lugar de HashRouter
//import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { getisLoggedIn, signOut, signIn } from "./libs/redux/slices/authSlice/authSlice";
import { useGetInfoUserMutation, useLogoutMutation } from "./libs/redux/slices/authSlice/authApiSlice";

function App() {
  const [getInfoUser] = useGetInfoUserMutation();
  const [logOutServer] = useLogoutMutation();
  const isLoggedIn = useSelector(getisLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) {
      const fetchDataUser = async () => {
        await getInfoUser()
          .then((response) => {
            dispatch(signIn({ name: response.data.data.USRIDXXX, userData: response.data }));
          })
          .catch(() => {});
      };
      fetchDataUser();
    }
  }, [isLoggedIn, getInfoUser, dispatch, logOutServer]);

  //hook que valida si la persona recarga o cierra la ventana y no completo el login pueda volver a iniciar
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      if (!isLoggedIn) {
        dispatch(signOut());
      }
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, [dispatch, isLoggedIn]);
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />} />
        <Route exact path="login" element={!isLoggedIn ? <LoginPage /> : <Navigate to="/" />} />
        <Route exact path="home" element={!isLoggedIn ? <LoginPage /> : <HomePage />}></Route>
        <Route exact path="process" element={!isLoggedIn ? <LoginPage /> : <ProcessPage />}></Route>
        <Route exact path="admin" element={!isLoggedIn ? <LoginPage /> : <AdminPage />}></Route>
        <Route exact path="percargo" element={!isLoggedIn ? <LoginPage /> : <PercargoPage />}></Route>
        <Route exact path="peruser" element={!isLoggedIn ? <LoginPage /> : <PerUserPage />}></Route>
        <Route exact path="visor/:url" element={<WindowPdfViewer />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
