import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import {
  useCreateTypeMutation,
  useCreateCompanyAreaSerieTypeMutation,
  useCreateCompanyAreaSerieTypeParentsMutation,
  useGetTypeParentOptionsMutation
} from "../../libs/redux/slices/cretipxxSlice/cretipxxApiSlice";

import {
  useGetMetadataByCompanyAreaSerieTypeMutation
} from "../../libs/redux/slices/filterSlice/filterApiSlice";

import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";

import { useDispatch } from "react-redux";
import Reutilxx from "./Reutilxx";
import Parent from "./Parent";

const Cretipxx = ({ onClose }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));
  const [insertType, { isLoading: isLoadingType }] = useCreateTypeMutation();
  const [insertCompanyAreaSerieType, { isLoading: isLoadingCompanyAreaSerieType }] =
    useCreateCompanyAreaSerieTypeMutation();
  const [insertCompanyAreaSerieTypeParents, { isLoading: isLoadingCompanyAreaSerieTypeParents }] =
    useCreateCompanyAreaSerieTypeParentsMutation();
  const [getTypeParentOptionsMutation] = useGetTypeParentOptionsMutation();
  const [getMetadataByCompanyAreaSerieTypeMutation] = useGetMetadataByCompanyAreaSerieTypeMutation();
  const [validated, setValidated] = useState(false);
  const [typeDescription, setTypeDescription] = useState("");
  const [company, setCompany] = useState("");
  const [area, setArea] = useState("");
  const [serie, setSerie] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [homologation, setHomologation] = useState("");
  const [documentParent, setDocumentParent] = useState(false);
  const [parentControls, setParentControls] = useState([
    new Parent(company, area)
  ]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && company !== "" && area !== "") {
      await insertType({
        TIPODESX: typeDescription,
        TIPOPATH: descriptionPath,
      })
        .unwrap()
        .then((response) => {
          saveCompanyAreaSerieType(response.TIPOIDXX);
        })
        .catch();
    } else {
      event.preventDefault();
    }
    setValidated(true);
  };

  const handleChangeCompany = (value) =>{
    setCompany(value);
    setDocumentParent(false);
    resetParentDetail(true);
    if(value===""){
      setArea("");
      setSerie("");
    }
  };

  const saveCompanyAreaSerieType = async (typeID) => {
    await insertCompanyAreaSerieType({
      EMPNITXX: company,
      AREAIDXX: area,
      SERIEIDX: serie,
      TIPOIDXX: typeID,
      HOMDIAXX: homologation,
      ISPARENT: documentParent ? "SI" : "NO"
    })
      .unwrap()
      .then(() => {
        if(documentParent){
          saveTypeParents(typeID);
        }else{
          onClose(false);
        }
      })
      .catch();
  };
  
  const saveTypeParents = async (typeID) => {
    await insertCompanyAreaSerieTypeParents({
      EMPNITXX: company,
      AREAIDXX: area,
      SERIEIDX: serie,
      TIPOIDXX: typeID,
      PARENTSX: parentControls
    })
      .unwrap()
      .then(() => {
        onClose(false);
      })
      .catch();
  };

  //Control check tiene documento padre
  const handleIsDocumentParent = (checked) => {
    setDocumentParent(checked);
  };

  const addParent = () => {
    const prevParentControls = [...parentControls];

    const newParentControl = new Parent(company, area);

    const newParentControls = [...prevParentControls, newParentControl];

    setParentControls(newParentControls);
  };

  const removeParent = (index) => {
    if (parentControls.length === 1) {
      return;
    }

    const newParentControls = [...parentControls];
    newParentControls.splice(index, 1);
    setParentControls(newParentControls);
  };

  const resetParentDetail = (checked) => {
    if(checked){
      const newParentControls = [new Parent(company, area)];
      setParentControls(newParentControls);
    }
  };

  const removeAllParentDetail = () => {
    resetParentDetail(documentParent);
  };

  const setParentValues = (index, key, value) => {
    const newParentControls = [...parentControls];
    const parentControl = newParentControls[index];
    parentControl[key] = value;
    setParentControls(newParentControls);
  };

  const handleParentSerieChange = async (companyParent, areaParent, value, index) => {
    const newParentControls = [...parentControls];
    const parentControl = newParentControls[index];

    let parentTypeData; 
    if(value!=="" && value!==null){
      parentTypeData = await FetchParentTypeOptions({ EMPNITXX: companyParent, AREAIDXX: areaParent, SERIEIDX: value, BANAPLPA: "NO" });
    }else{
      parentTypeData = [];
    }
    parentControl.TYPEOPTS = parentTypeData;
    parentControl.METAOPTS = [];
    parentControl.METIDXXX = "";
    setParentControls(newParentControls);
  };

  const handleParentTypeChange = async (companyParent, areaParent, serie, value, index) => {
    const newParentControls = [...parentControls];
    const parentControl = newParentControls[index];
    
    let parentMetaData = await FetchParentMetadataOptions({ EMPNITXX: companyParent, AREAIDXX: areaParent, SERIEIDX: serie, TIPOIDXX: value, METIDXXX: "null" });
    parentControl.METAOPTS = parentMetaData;
    setParentControls(newParentControls);
  };

  const FetchParentTypeOptions = async (params) => {
    return await getTypeParentOptionsMutation(params)
      .unwrap()
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };
  
  const FetchParentMetadataOptions = async (params) => {
    return await getMetadataByCompanyAreaSerieTypeMutation(params)
      .unwrap()
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  };

  const setParentTypeOptionByItem = (serie, value, index) => {
    const newParentControls = [...parentControls];
    const parentControl = newParentControls[index];
    parentControl.TIPOIDPA =value;
    parentControl.METAOPTS = [];
    parentControl.METIDXXX = "";
    setParentControls(newParentControls);
    if(value!=="" && value!==null){
      handleParentTypeChange(company, area, serie, value, index);
    }
  };

  const setParentMetadataOptionByItem = (value, index, AREAIDPA, SERIEIPA, TIPOIDPA) => {
    const newParentControls = [...parentControls];
    const isDuplicate = parentControls.some((control) => 
      control.AREAIDPA === AREAIDPA &&
      control.SERIEIPA === SERIEIPA &&
      control.TIPOIDPA === TIPOIDPA &&
      control.METIDXXX === value
    );

    if (isDuplicate) {
      alert('Este combinación ya ha sido seleccionada. Por favor elige otra.');
      return;
    }

    const parentControl = newParentControls[index];
    parentControl.METIDXXX =value;
    setParentControls(newParentControls);
  };

  return isLoadingType || isLoadingCompanyAreaSerieType || isLoadingCompanyAreaSerieTypeParents? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Tipo*</Form.Label>
          <InputGroup>
            <Form.Control
              value={typeDescription}
              type="text"
              required
              onChange={(e) => {
                setTypeDescription(e.target.value);
              }}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre del Tipo</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => {
                setDescriptionPath(e.target.value);
              }}
              minLength={8}
              maxLength={8}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row md={12} className="mt-3">
        <Reutilxx
          company={company}
          handleChangeCompany={handleChangeCompany}
          area={area}
          handleChangeArea={setArea}
          serie={serie}
          handleChangeSerie={setSerie}
          documentParent={documentParent}
          removeAllParentDetail={removeAllParentDetail}
          setParentValues={setParentValues}
        />
        <Row md={12} className="mt-3">
          <Form.Group as={Col} md={4}>
            <Form.Label>Homologación DIAN</Form.Label>
            <Form.Control
              type="text"
              value={homologation}
              onChange={(e) => {
                setHomologation(e.target.value);
              }}
            />
          </Form.Group>
          {company && area && serie &&(
            <Form.Group as={Col} md={4}>
              <Form.Label>
                <br />
              </Form.Label>
              <Form.Check
                label="Tiene documento Padre"
                type="checkbox"
                checked={documentParent}
                onChange={(e) => {
                  handleIsDocumentParent(e.target.checked);
                  resetParentDetail(e.target.checked);
                }}
              />
            </Form.Group>
          )}
        </Row>
          {documentParent && (
            <>
              {parentControls.map((item, index) => (
                <Row md={12} className="mt-3 m-3">
                  <Reutilxx
                    mustShowCompany={false}
                    company={company}
                    handleChangeCompany={setCompany}
                    area={item.AREAIDPA}
                    handleChangeArea={() =>{}}
                    serie={item.SERIEIPA}
                    handleChangeSerie={() =>{}}
                    documentParent={documentParent}
                    removeAllParentDetail={removeAllParentDetail}
                    setParentValues={setParentValues}
                    index={index}
                    handleParentSerieChange={handleParentSerieChange}
                  />
                  <Form.Group as={Col} md={3}>
                    <Form.Label>Tipo Documental Padre:*</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setParentTypeOptionByItem(item.SERIEIPA, e.target.value, index)
                      }}
                      value={item.TIPOIDPA}
                      disabled={item.SERIEIPA===null || item.SERIEIPA===""}
                      required
                    >
                      <option value={""}>[SELECCIONE]</option>
                      {item.TYPEOPTS.length > 0 && (
                        <>
                          {item.TYPEOPTS.map((type) => (
                            <option key={`${type.TIPOIDXX}`} value={type.TIPOIDXX}>
                              {type.TIPODESX}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Debe Seleccionar un Tipo Documental Padre</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={2}>
                    <Form.Label>Metadato:*</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setParentMetadataOptionByItem(e.target.value, index, item.AREAIDPA, item.SERIEIPA, item.TIPOIDPA)
                      }}
                      value={item.METIDXXX}
                      disabled={item.TIPOIDPA===null || item.TIPOIDPA===""}
                      required
                    >
                      <option value={""}>[SELECCIONE]</option>
                      {item.METAOPTS.length > 0 && (
                        <>
                          {item.METAOPTS.map((type) => (
                            <option key={`${type.METIDXXX}`} value={type.METIDXXX}>
                              {type.METDESXX}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Debe Seleccionar un Metadato</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md={1} className="mt-4">
                  <Form.Label style={{paddingTop: "12px"}}>&nbsp;</Form.Label>
                    <span className="bi bi-plus-circle-fill p-2" style={{ color: "#fba500", fontsize: "1.5em" }}
                      onClick={() => addParent()}/>
                      { parentControls.length > 1 && (
                        <span className="bi bi-x-circle-fill" style={{ color: "#bdb9b3", fontsize: "1.5em"}}
                          onClick={() => removeParent(index)}/>
                        )
                      }
                  </Form.Group>
                </Row>
              ))}
            </>
          )}
        <Row md={12} className="mt-3">
          <Col className="offset-10" md={1}>
            <Button type="Submit">Guardar</Button>
          </Col>
          <Col md={1}>
            <Button
              onClick={(e) => {
                setValidated(false);
                setTypeDescription("");
                setDescriptionPath("");
                setCompany("");
                setArea("");
                setSerie("");
                handleIsDocumentParent(false);
                setDocumentParent(false);
              }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};

export default Cretipxx;
