import React from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const columnsTransform = (
  columns,
  buttons,
  functionDelete,
  functionView,
  functionShow,
  functionRow,
  functionEdit,
  updateRow,
  updatedData,
  functionDeleteCargo,
  functionDeleteUsuario,
  functionMessage
) => {
  const handleCheckboxChange = (row, position, value) => {
    const updatedRow = updateRow(row, position, value);
    console.log(updatedRow);
  };

  const columnsFormat = columns.map((item) => {
    let column = {
      id: item.id,
      name: item.title,
      selector: (row) => {
        return row[item.position];
      },
      width: item.width,
      cell: (row) => {
        if (item.position === "EDITXXXX" || item.position === "DELETEXX") {
          const isChecked = row[item.position] === "SI";

          const handleCheckboxClick = (e) => {
            let updatedValue = e.target.checked ? "SI" : "NO";

            if (item.position === "EDITXXXX") {
              handleCheckboxChange(row, item.position, updatedValue);
            } else if (item.position === "DELETEXX") {
              handleCheckboxChange(row, item.position, updatedValue);
            }
          };

          return (
            <Form.Check
              type="checkbox"
              defaultChecked={isChecked}
              onClick={(e) => handleCheckboxClick(e)}
              style={{ cursor: "pointer" }}
            />
          );
        }
        return row[item.position];
      },
    };
    return column;
  });

  columnsFormat.push({
    id: "idAcciones",
    name: " ",
    cell: (row) => (
      <>
        {buttons?.map((btn) => {
          return (
            <OverlayTrigger
              key={btn?.id}
              placement="top"
              overlay={
                <Tooltip id={`tooltip-${btn?.id}`}>
                  {btn?.title}
                </Tooltip>
              }
            >
            <span
              style={{ cursor: "pointer", fontSize: "29px" }}
              className={btn?.class}
              key={btn?.id}
              onClick={() => {
                switch (btn?.id) {
                  case "idVer":
                    functionView("ASOCIAR METADATO");
                    functionShow(true);
                    functionRow(row);
                    break;
                  case "idEliminar":
                    functionDelete({
                      EMPNITXX: row.EMPNITXX,
                      AREAIDXX: row.AREAIDXX,
                      SERIEIDX: row.SERIEIDX,
                      TIPOIDXX: row.TIPOIDXX,
                      METIDXXX: row.METIDXXX,
                    });
                    break;
                  case "idEditar":
                    const updatedNewRow = updatedData.find((item) => {
                      return (
                        ((item.CARIDXXX && item.CARIDXXX === row.CARIDXXX) ||
                          (item.USRIDXXX && item.USRIDXXX === row.USRIDXXX)) &&
                        item.EMPNITXX === row.EMPNITXX
                      );
                    });

                    functionEdit({
                      URLXXXXX: row.CARIDXXX
                        ? "cargosporareaserietipo/updateAll"
                        : "usuariosporareaserietipo/updateAll",
                      IDKEYXXX: row.CARIDXXX ? row.CARIDXXX : row.USRIDXXX,
                      EMPNITXX: row.EMPNITXX,
                      EDITXXXX: updatedNewRow.EDITXXXX,
                      DELETEXX: updatedNewRow.DELETEXX,
                    });
                    break;
                  case "idEditarPermisos":
                  case "idEditarProceso":
                    functionView("EDITAR");
                    functionShow(true);
                    functionRow(row);
                    break;
                  case "idVerPermisos":
                  case "idVerProceso":
                    functionView("VISUALIZAR");
                    functionShow(true);
                    functionRow(row);
                    break;
                  case "idEliminarCargo":
                    functionDeleteCargo({
                      TIPOOPEX: "MASIVO",
                      EMPNITXX: row.EMPNITXX,
                      CARIDXXX: row.CARIDXXX,
                      AREAIDXX: row.AREAIDXX,
                      SERIEIDX: row.SERIEIDX,
                      TIPOIDXX: row.TIPOIDXX
                    }).then((response) => {
                      const cMessage = response.data.data.message;
                      functionMessage(cMessage);
                    })
                    .catch((error) => {
                      console.error("Error deleting permission:", error);
                    });
                    break;
                  case "idEliminarUsuario":
                    functionDeleteUsuario({
                      TIPOOPEX: "MASIVO",
                      EMPNITXX: row.EMPNITXX,
                      USRIDXXX: row.USRIDXXX,
                      AREAIDXX: row.AREAIDXX,
                      SERIEIDX: row.SERIEIDX,
                      TIPOIDXX: row.TIPOIDXX
                    }).then((response) => {
                      const cMessage = response.data.data.message;
                      functionMessage(cMessage);
                    })
                    .catch((error) => {
                      console.error("Error deleting permission:", error);
                    });
                    break;
                  case "idClonarPermisos":
                    functionView("CLONAR");
                    functionShow(true);
                    functionRow(row);
                    break;
                  default:
                    break;
                }
              }}
            ></span>
          </OverlayTrigger>

          );
        })}
      </>
    ),
  });

  return columnsFormat;
};
