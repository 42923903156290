import './Viewerstyles.css';
import PDFViewer from './PDFViewer';
import { useParams } from "react-router-dom";
//import { useLocation } from "react-router-dom";
import { getisLoggedIn} from "../../libs/redux/slices/authSlice/authSlice";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';

const WindowPdfViewer = () => {
  const isLoggedIn = useSelector(getisLoggedIn);
  const params = useParams();
  //const location = useLocation();
  //const queryParams = new URLSearchParams(location.search);

  // Función para descifrar los datos
  const decryptData = (encryptedData) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, 'GenioVisorPDF');
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  };

  // Descifrar los datos de la URL
  const decryptedParams = decryptData(params.url);

  /**
   * Logica para buscar el valor del permiso de Descargar e Imprimir
   */
  let permPrint = true;
  let permDownl = true;
  return (
    <>
      {isLoggedIn && (
        <div style={{ height: "850px" }}>
          <PDFViewer
            pdfUrl={decryptedParams.url}
            showPrint={permPrint}
            showDownload={permDownl}
          />
        </div>
      )}
      {!isLoggedIn && (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default WindowPdfViewer;
