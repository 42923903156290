import { Col, Form } from "react-bootstrap";
import {
  useGetCompanysQuery,
  useLazyGetAreasByCompanyQuery,
  useLazyGetSeriesByAreaQuery,
} from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useEffect } from "react";

/**
 * Componente para la reutilizacion de los campos empresa, proceso, serie
 * @returns Component
 */
const Reutilxx = (props) => {
  const { company, handleChangeCompany, area, handleChangeArea, serie, handleChangeSerie, mustShowCompany = true, index = -1, setParentValues, removeAllParentDetail, handleParentSerieChange } = props;
  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [findAreasByCompany, { data: areas, isLoading: isLoadingAreas, isUninitialized: isUninitializedAreas }] =
    useLazyGetAreasByCompanyQuery();
  const [findSeriesByArea, { data: series, isLoading: isLoadingSeries, isUninitialized: isUninitializedSeries }] =
    useLazyGetSeriesByAreaQuery();

  useEffect(() => {
    if (company !== "") {
      findAreasByCompany({ EMPNITXX: company, AREAIDXX: "null" });
    }
  }, [company, findAreasByCompany]);

  useEffect(() => {
    if (company !== "" && area !== "") {
      findSeriesByArea({ EMPNITXX: company, AREAIDXX: area, SERIEIDX: "null" });
    }
  }, [company, area, findSeriesByArea]);

  return (
    <>
      {mustShowCompany && (
        <Form.Group as={Col} md={4}>
          <Form.Label>Empresa:*</Form.Label>
          <Form.Select
            value={company}
            required
            onChange={(e) => {
              handleChangeCompany(e.target.value);
              removeAllParentDetail();
            }}
          >
            {isLoadingCompanys ? (
              <option value={""}>CARGANDO...</option>
            ) : (
              companys?.data?.map((company, index) => {
                return index === 0 ? (
                  <>
                    <option value={""}>[SELECCIONE]</option>
                    <option key={company.EMPNITXX} value={company.EMPNITXX}>
                      {company.EMPDESXX}
                    </option>
                  </>
                ) : (
                  <option key={company.EMPNITXX} value={company.EMPNITXX}>
                    {company.EMPDESXX}
                  </option>
                );
              })
            )}
          </Form.Select>
          <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
        </Form.Group>
      )}
      <Form.Group as={Col} md={mustShowCompany?4:3}>
        <Form.Label>Proceso:*</Form.Label>
        <Form.Select
          onChange={(e) => {
            if(index===-1){
              handleChangeArea(e.target.value);
            }else{
              setParentValues(index, "AREAIDPA", e.target.value);
            }
          }}
          value={area}
          required
          disabled={company===null || company===""}
        >
          {isLoadingAreas || isUninitializedAreas ? (
            <option value={""}>[SELECCIONE]</option>
          ) : (
            areas?.data?.map((area, index) => {
              return index === 0 ? (
                <>
                  <option value={""}>[SELECCIONE]</option>
                  <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                    {area.AREADESX}
                  </option>
                </>
              ) : (
                <option key={`${area.EMPNITXX}-${area.AREAIDXX}`} value={area.AREAIDXX}>
                  {area.AREADESX}
                </option>
              );
            })
          )}
        </Form.Select>
        <Form.Control.Feedback type="invalid">Debe Seleccionar un Proceso</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md={mustShowCompany?4:3}>
        <Form.Label>Serie:*</Form.Label>
        <Form.Select
          onChange={(e) => {
            if(index===-1){
              handleChangeSerie(e.target.value);
            }else{
              handleParentSerieChange(company, area, e.target.value, index);
              setParentValues(index, "SERIEIPA", e.target.value);
            }
          }}
          value={serie}
          required
          disabled={area===null || area===""}
        >
          {isLoadingSeries || isUninitializedSeries ? (
            <option value={""}>[SELECCIONE]</option>
          ) : (
            series?.data?.map((serie, index) => {
              return index === 0 ? (
                <>
                  <option value={""}>[SELECCIONE]</option>
                  <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                    {serie.SERIEDES}
                  </option>
                </>
              ) : (
                <option key={`${serie.EMPNITXX}-${serie.AREAIDXX}-${serie.SERIEIDX}`} value={serie.SERIEIDX}>
                  {serie.SERIEDES}
                </option>
              );
            })
          )}
        </Form.Select>
        <Form.Control.Feedback type="invalid">Debe Seleccionar una Serie</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default Reutilxx;
