import { Container, Modal, Row, Carousel, Col, Stack, Form, InputGroup } from "react-bootstrap";
import {
  FiPlusCircle,
  FiMinusCircle,
  FiRotateCw,
  FiRotateCcw,
  FiDownload,
  FiPrinter,
  FiArrowLeftCircle,
  FiArrowRightCircle
} from 'react-icons/fi';
import Loadingx from "../Loadingx/Loadingx";
import { useState, useEffect } from "react";
//import { useSelector } from "react-redux";
import { useLazyGetFilesFiltersQuery } from "../../libs/redux/slices/Trkgfile/trkgfileApislice";
import './Viewerstyles.css';

const Modaview = ({ modalView, setModalView, jsonFilters, currentImage, dataEdit }) => {//
  const [getFilesxfilters] = useLazyGetFilesFiltersQuery();
  const [totalImages, setTotalImages] = useState(0);
  const [dataImages, setDataImages] = useState([]);
  const [index, setIndex] = useState(0);
  const [codProducto, setCodProducto] = useState("");
  const [factor, setFactor] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });
  const zoom = 100; // Estado inicial zoom
  const [inputZoom, setInputZoom] = useState(""); // Estado input zoom
  const [zoomLevels, setZoomLevels] = useState([]); // Estado zoom x cada imagen

  const handleSelect = (indexSel) => {
    setIndex(indexSel);
    setCodProducto(dataImages[indexSel].JSONMETX.CODPRODU);
    setFactor(1);
    setContainerPosition({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (jsonFilters) {
      getFilesxfilters({ filters: jsonFilters }, { preferCacheValue: false })
        .unwrap()
        .then((response) => {
          let data = response.data;
          const ind = data.findIndex((item) => item.DOCDURLX === currentImage);
          const objCurrent = data[ind];
          if (ind !== -1) {
            data = data.filter((_, i) => i !== ind);
            data.unshift(objCurrent);
          }
          setTotalImages(data.length);
          setDataImages(
            data.map(item => ({
            ...item,
            rotation: 0 // seteo inicial 0 deg
            }))
          );
          setCodProducto(data[0].JSONMETX.CODPRODU);
          setZoomLevels(
            data.map(() => 100) // seteo zoom inicial 100%
          );
        });
    }
  }, [jsonFilters, getFilesxfilters, currentImage]);

  const toggleMaximize = () => {
    setFactor((prevFactor) => (prevFactor <= 2 ? prevFactor + 0.5 : prevFactor));
  };

  const toggleMinimize = () => {
    setFactor((prevFactor) => (prevFactor > 1 ? prevFactor - 0.5 : prevFactor));
    setContainerPosition({ x: 0, y: 0 });
  };

  const rotateLeftImage = () => {
    const newDataImages = [...dataImages];
    newDataImages[index] = {
      ...newDataImages[index],
      rotation: (newDataImages[index].rotation - 90 + 360) % 360
    };
    setDataImages(newDataImages);
  };

  const rotateRightImage = () => {
    const newDataImages = [...dataImages];
    newDataImages[index] = {
      ...newDataImages[index],
      rotation: (newDataImages[index].rotation + 90) % 360
    };
    setDataImages(newDataImages);
  };

  const handleMouseDown = (e) => {
    if (factor > 1) {
      setDragging(true);
      setPosition({
        x: e.clientX - containerPosition.x,
        y: e.clientY - containerPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      e.preventDefault();
      const newPosX = e.clientX - position.x;
      const newPosY = e.clientY - position.y;
      setContainerPosition({ x: newPosX, y: newPosY });
    }
  };

  const downloadCurrentImage = () => {
    const currentImageData = dataImages[index];
    fetch(currentImageData.DOCDURLX, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = currentImageData.DOCDURLX.substring(currentImageData.DOCDURLX.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => console.log("No se descargo la imagen"));
  };

  //Funcion para imprimir una imagem
  const printImage = () => {
    const currentImageData = dataImages[index];
    let urlImage = currentImageData.DOCDURLX;
    const url = currentImageData.DOCDURLX.split("/");
    let image = document.createElement('img');
    image.src = urlImage;
    //Funcion para el precargado de la imagen
    image.onload = function() {
      //Abro un nueva ventana
      let openWindow = window.open('', '_blank');
      if (openWindow) {
        //Agrego el contenido de la imagen en una nueva ventana
        openWindow.document.write('<html><head><title>' + url[url.length - 1] + '</title></head><center><img src="' + urlImage + '"></center></html>');
        //Imprimo el contenido
        openWindow.print();
        openWindow.close();
      }
    };
  }

  const handleZoomChange = (event) => {
    //setInputZoom(event.target.value);
    const newZoomLevels = [...zoomLevels];
    newZoomLevels[index] = event.target.value;
    setZoomLevels(newZoomLevels);
  };

  const applyZoom = () => {
    const newZoomLevels = [...zoomLevels];
    if (inputZoom && !isNaN(inputZoom)) {
      newZoomLevels[index] = parseInt(inputZoom);
      setZoomLevels(newZoomLevels);
      setInputZoom("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      applyZoom();
    }
  };

  return (
    <>
      <Modal show={modalView} onHide={() => setModalView(false)} dialogClassName="modal-lg">
        <Modal.Header closeButton className="modal-header">
          {dataImages.length > 0 ? (
            <Container>
              <Stack direction="horizontal" gap={1}>
              <div className="vr" />
                <div className="" style={{ color: '#fd7e14' , flex: "0 1 47%" }}><b>COD:</b> {codProducto}</div>
                <div className="" style={{ color: '#fd7e14' }}><b>{index + 1} / {totalImages}</b></div>
                <div className="ms-auto">
                  <InputGroup className="mt-4">
                    <Form.Control
                      type="text"
                      placeholder="%"
                      // value={inputZoom}
                      onChange={handleZoomChange}
                      onBlur={applyZoom}
                      onKeyDown={handleKeyDown}
                      maxLength="3"
                      style={{
                              width: '55px',
                              borderColor: '#fd7e14',
                              flex: 'none',
                              position: 'relative',
                              top: '-12px',
                              textAlign: 'center'
                            }}
                    />
                  </InputGroup>
                </div>
                <div className="ps-2">
                  <FiPlusCircle onClick={toggleMaximize} className="custom-buttons" title="aumentar"/>
                </div>
                <div className="pe-3">
                  <FiMinusCircle onClick={toggleMinimize} className="custom-buttons" title="disminuir"/>
                </div>
                <div className="">
                  <FiRotateCcw onClick={rotateLeftImage} className="custom-buttons" title="rotar izq"/>
                </div>
                <div className="pe-2">
                  <FiRotateCw onClick={rotateRightImage} className="custom-buttons" title="rotar der"/>
                </div>
                <div className="ps-2">
                  <FiDownload onClick={downloadCurrentImage} className="custom-buttons" title="descargar"/>
                </div>
                <div className="pe-2">
                  <FiPrinter onClick={printImage} className="custom-buttons" title="imprimir"/>
                </div>
                <div className="vr" />
              </Stack>
            </Container>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                {dataImages.length > 0 ? (
                  <Carousel
                    style={{ height: "100%" }}
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                    prevIcon={<FiArrowLeftCircle color="black" size={33} />}
                    nextIcon={<FiArrowRightCircle color="black" size={33} />}
                  >
                    {dataImages.map((data, i) => (
                      <Carousel.Item key={i} className="text-center">
                        <div
                          style={{
                            transform: `scale(${factor * zoom / 100}) translate(${containerPosition.x}px, ${containerPosition.y}px) rotate(${data.rotation}deg)`,
                            cursor: factor > 1 ? "move" : "auto",
                            overflow: factor > 1 ? "auto" : "hidden",
                            width: "100%",
                            height: "100%",
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseUp={handleMouseUp}
                          onMouseMove={handleMouseMove}
                        >
                          <img
                            src={data.DOCDURLX}
                            alt={`Item ${i}`}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              transform: `scale(${zoomLevels[i] / 100})`,
                              transition: 'transform 0.25s ease'
                            }}
                          />
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  <Loadingx />
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Modaview;
