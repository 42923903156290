import { createSlice } from "@reduxjs/toolkit";

const trkgfileSlice = createSlice({
  name: "trkgfile",
  initialState: {
    filters: "",
    customFilters: {
      page: 1,
      perPage: process.env.REACT_APP_FILE_BY_PAGE,
      sorts: "TYPEXXXX,IDARCHXX",
    },
    filterSeries: {},
    reports: false,
    filtsNav: "",
    reset: false,
    navBar: true,
    numDo: "",
    cliidxx: ""
  },
  reducers: {
    setFiltersTkr: (state, action) => {
      state.filters = action.payload;
    },
    resetfilters: (state) => {
      state.filters = null;
    },
    setCustomFilters: (state, action) => {
      state.customFilters = action.payload;
    },
    setFilterSeries: (state, action) => {
      state.filterSeries = action.payload;
    },
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    setFiltsNav: (state, action) => {
      state.filtsNav = action.payload;
    },
    setReset: (state, action) => {
      state.reset = action.payload;
    },
    setNavBar: (state, action) => {
      state.navBar = action.payload;
    },
    setNumDo: (state, action) => {
      state.numDo = action.payload;
    },
    setCliidxx: (state, action) => {
      state.cliidxx = action.payload;
    }
  },
});
export const {
  setFiltersTkr,
  resetfilters,
  setCustomFilters,
  setFilterSeries,
  setReports,
  setFiltsNav,
  setReset,
  setNavBar,
  setNumDo,
  setCliidxx
} = trkgfileSlice.actions;
export default trkgfileSlice.reducer;

export const getFiltersTkr = (state) => {
  return state.trkgfile.filters;
}
export const getCustomFilters = (state) => {
  return state.trkgfile.customFilters;
};
export const getFilterSeries = (state) => {
  return state.trkgfile.filterSeries;
}
export const getReports = (state) => {
  return state.trkgfile.reports;
}
export const getFiltsNav = (state) => {
  return state.trkgfile.filtsNav;
}
export const getReset = (state) => {
  return state.trkgfile.reset;
}
export const getNavBar = (state) => {
  return state.trkgfile.navBar;
}
export const getNumDo = (state) => {
  return state.trkgfile.numDo;
}
export const getCliidxx = (state) => {
  return state.trkgfile.cliidxx;
}
