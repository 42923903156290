import es_ES from '@react-pdf-viewer/locales/lib/es_ES.json';
import React, { useState, useEffect, useRef } from 'react';
import {
  FiFile,
  FiPlusCircle,
  FiMinusCircle,
  FiRotateCw,
  FiRotateCcw,
  FiSearch,
  FiDownload,
  FiPrinter
} from 'react-icons/fi';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { printPlugin } from '@react-pdf-viewer/print';
import { searchPlugin } from '@react-pdf-viewer/search';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { Form, InputGroup } from "react-bootstrap";
import { Worker, RotateDirection, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';

function PDFViewer({ pdfUrl, showPrint, showDownload }) {
  const fileName = pdfUrl.split('/').pop();
  const [zoom, setZoom] = useState(100);
  //const [enableZoomOut, setEnableZoomOut] = useState(true);
  //const [enableZoomIn, setEnableZoomIn] = useState(true);
  const theme = 'light';
  const [thumbnailVisible, setThumbnailVisible] = useState(false);
  const rotatePluginInstance = rotatePlugin();
  const { Rotate } = rotatePluginInstance;
  //Plugins
  const zoomPluginInstance = zoomPlugin();
  const printPluginInstance = printPlugin();
  const searchPluginInstance = searchPlugin({ enableShortcuts: true });
  const thumbnailPluginInstance = thumbnailPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin({ enableShortcuts: true });
  //Use of Plugins
  const { CurrentScale, ZoomIn, ZoomOut, zoomTo } = zoomPluginInstance;
  const { Print } = printPluginInstance;
  const { ShowSearchPopover } = searchPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;
  const [currentScale, setCurrentScale] = useState(100);
  const searchIconRef = useRef(null);
  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  /**
   * Funcion que controla el Boton del Zoom para aumentar
   */
  const handleZoomInClick = () => {
    const newScale = Math.min(currentScale + 10, 500);
    setCurrentScale(newScale);
    zoomTo(newScale / 100);
  };

  /**
   * Funcion que controla el Boton del Zoom para reducir
   */
  const handleZoomOutClick = () => {
    const newScale = Math.max(currentScale - 10, 25);
    setCurrentScale(newScale);
    zoomTo(newScale / 100);
  };

  useEffect(() => {

    /**
     * Se encarga de los comandos del teclado del visor
     * @param {*} e
     */
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === 'f' || e.key === 'F')) {
        e.preventDefault();
        if (searchIconRef.current) {
          searchIconRef.current.click();
        }
      }

      if (e.ctrlKey && (e.key === 'p' || e.key === 'P')) {
        e.preventDefault();
        if (showPrint) {
          e.preventDefault();
          printPluginInstance.print();
        } else {
          alert('Usuario No tiene Permisos para Imprimir');
        }
      }

      if (e.ctrlKey && (e.key === 's' || e.key === 'S')) {
        e.preventDefault();
      }
    };

    /**
     * Funcion que maneja el control del clic derecho
     * menu contextual
     * @param event
     */
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
    // eslint-disable-next-line
  }, [])

  /**
   * Funcion encargada de controlar el Zoom del PDF
   * @param {*} e
   */
  function handleZoomChange(e) {
    const newValue = e.target.value.replace(/\D/g, '');
    let newZoom = Math.min(500, Math.max(25, parseInt(newValue)));
    if (newZoom < 25) {
      newZoom = 25;
    } else if (newZoom > 500) {
      newZoom = 500;
    }

    setZoom(newZoom);
    setCurrentScale(newZoom);
    zoomTo(newZoom / 100);
  }

  /**
   * Funcion encargada de renderizar la top bar
   * del visor
   */
  const renderThumbnailItem = (props) => (
    <div
      key={props.key}
      data-testid={`thumbnail-${props.pageIndex}`}
      style={{
        backgroundColor: props.pageIndex === props.currentPage ? 'rgba(0, 0, 0, 0.3)' : '#fff',
        cursor: 'pointer',
        padding: '0.5rem',
        width: '100%',
      }}
    >
      <div style={{ marginBottom: '0.5rem' }} onClick={props.onJumpToPage}>
        {props.renderPageThumbnail}
      </div>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          margin: '0 auto',
          width: '100px',
        }}
      >
        {props.renderPageLabel}
        {/*Add features here*/}
      </div>
    </div>
  );

  /**
   * Funcion encargada del zoom del input
   */
  const handleZoom = (e) => {
    setZoom(parseInt(e.scale * 100));
    /*setEnableZoomOut(e.scale > 0.25);
    setEnableZoomIn(e.scale < 5);*/
  };

  /**
   * Funcion que ejecuta el cambio de zoom
   * del input con la tecla Enter
   */
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleZoomChange(e);
    }
  };

  return (
    <>
      <div className="pdf-viewer-container">
        <div className="top-bar">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'start' }}>
              <FiFile size={32} style={{ marginRight: '5px', color: '#fd7e14', cursor: 'pointer' }} onClick={() => setThumbnailVisible(!thumbnailVisible)} />
              {fileName}
            </div>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <CurrentPageLabel>
                {(props) => (
                  <label class="counter" style={{ color: '#fd7e14' }}>{`${props.currentPage + 1} / ${props.numberOfPages}`}</label>
                )}
              </CurrentPageLabel>
              <div className="zoom-input" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2' }}>
                <CurrentScale>
                  {(props) =>
                    <>
                      <InputGroup className="mt-4">
                        <Form.Control
                          type="text"
                          placeholder="%"
                          onBlur={handleZoomChange}
                          onKeyDown={handleKeyDown}
                          defaultValue={zoom}
                          maxLength="3"
                          style={{ width: '60px', marginLeft: '5px', borderColor: '#fd7e14', flex: 'none', position: 'relative', top: '-12px' }}
                        />
                      </InputGroup>
                    </>
                  }
                </CurrentScale>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <ZoomOut>
                  {
                    (props) => (
                      <FiMinusCircle
                        size={60}
                        style={{
                          color: '#fd7e14',
                          cursor: 'pointer'
                        }}
                        onClick={handleZoomOutClick}
                      />
                    )
                  }
                </ZoomOut>
                <ZoomIn>
                  {
                    (props) => (
                      <FiPlusCircle
                        size={60}
                        style={{
                          color: '#fd7e14',
                          cursor: 'pointer'
                        }}
                        onClick={handleZoomInClick}
                      />
                    )
                  }
                </ZoomIn>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <div style={{ display: 'flex', flex: 1, alignItems: 'end' }}>
              <ShowSearchPopover>
                {
                  (props) => (
                    <div ref={searchIconRef} onClick={props.onClick}>
                      <FiSearch
                        size={32}
                        style={{
                          color: '#fd7e14',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  )
                }
              </ShowSearchPopover>&nbsp;&nbsp;
              <Rotate direction={RotateDirection.Backward}>
                {(props) => (
                  <FiRotateCcw
                    size={32}
                    style={{
                      color: '#fd7e14',
                      cursor: 'pointer'
                    }}
                    onClick={props.onClick}
                  />
                )}
              </Rotate>&nbsp;&nbsp;
              <Rotate direction={RotateDirection.Forward}>
                {(props) => (
                  <FiRotateCw
                    size={32}
                    style={{
                      color: '#fd7e14',
                      cursor: 'pointer'
                    }}
                    onClick={() => props.onClick()}
                  />
                )}
              </Rotate>&nbsp;&nbsp;
              {showDownload && (
                <>
                  <Download>
                    {(downloadProps) => (
                      <FiDownload
                        size={32}
                        style={{ color: '#fd7e14', cursor: 'pointer' }}
                        onClick={downloadProps.onClick}
                      />
                    )}
                  </Download>&nbsp;&nbsp;&nbsp;
                </>
              )}
              {showPrint && (
                <>
                  <Print>
                    {(props) => (
                      <FiPrinter
                        size={32}
                        style={{ color: '#fd7e14', cursor: 'pointer' }}
                        onClick={props.onClick}
                      />
                    )}
                  </Print>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            height: '100%',
          }}
        >
          {thumbnailVisible && (
            <div
              style={{
                borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                width: '20%',
                backgroundColor: theme === 'dark' ? '#525659' : ''
              }}
            >
              <Thumbnails renderThumbnailItem={renderThumbnailItem} />
            </div>
          )}
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
              backgroundColor: theme === 'dark' ? '#525659' : ''
            }}
          >
            <Viewer
              fileUrl={pdfUrl}
              initialRotation={0}
              defaultScale={SpecialZoomLevel.ActualSize}
              localization={es_ES}
              onZoom={handleZoom}
              theme={theme}
              plugins={[
                getFilePluginInstance,
                pageNavigationPluginInstance,
                printPluginInstance,
                rotatePluginInstance,
                searchPluginInstance,
                thumbnailPluginInstance,
                zoomPluginInstance,
              ]}
            />
          </div>
        </div>
      </Worker>
    </>
  );
}

export default PDFViewer;
