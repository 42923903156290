import { apiSlice } from "../apiSlice";

export const trkgfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFilters: builder.query({
      query: (parameters) => ({
        url: `/paquetesdocumentales?${parameters.filters}&per_page=${parameters.per_page}&page=${parameters.page}&reports=${parameters.reports}&environment=GESTOR&sorts=${parameters.sorts}`,
        method: "GET",
      }),
      providesTags: ["Consult"],
    }),
    getDownloadFiles: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/getUnionPdf`,
        params: { ...parameters }
      })
    }),
    getSeriesxFilters: builder.mutation({
      query: (parameters) => ({
        url: `/getSeriesxFilters?${parameters.filters}`,
        method: "GET",
      })
    }),
    getHistory: builder.query({
      query: (parameters) => ({
        url: "Gestorlog/Serchlog",
        method: "POST",
        body: { ...parameters }
      })
    }),
    updateFile: builder.mutation({
      query: (parameters) => ({
        url: `paquetesdocumentales/${parameters.IDARCHXX}`,
        method: "PUT",
        body: { ...parameters }
      }),
      invalidatesTags: ["Consult"],
    }),
    getdownloadForZip: builder.mutation({
      query: (parameters) => ({
        url: `${process.env.REACT_APP_GESTOR_BALANCER}/getDownloadForZip`,
        params: { ...parameters }
      }),
    }),
    getDeleteFileTmp: builder.mutation({
      query: (parameters) => ({
        url: `/tmp/${parameters}`,
        method: "DELETE",
      })
    }),
    getTrazabilida: builder.query({
      query: (parameters) => ({
        url: `/trazabilidad?filters[IDARCHXX]=${parameters.IDARCHXX}&sorts=DOCCSCID&anio=${parameters.anio}`,
        method: "GET",
      }),
    }),
    getFilesxfilters: builder.mutation({
      query: (parameters) => ({
        url: `/paquetesdocumentales?${parameters.filters}`,
        method: "GET",
      })
    }),
    getFilesFilters: builder.query({
      query: (parameters) => ({
        url: `/paquetesdocumentales?${parameters.filters}`,
        method: "GET",
      })
    }),
    getDownloadZipQuole: builder.query({
      query: (parameters) => ({
        url: `/sendEmailPdfSepZip`,
        params: { ...parameters }
      })
    }),
    getDownloadPdfQuole: builder.query({
      query: (parameters) => ({
        url: `/sendEmailPdfUnited`,
        params: { ...parameters }
      })
    }),
  }),
});

export const {
  useGetFiltersQuery,
  useGetDownloadFilesMutation,
  useGetSeriesxFiltersMutation,
  useGetHistoryQuery,
  useUpdateFileMutation,
  useGetdownloadForZipMutation,
  useGetDeleteFileTmpMutation,
  useGetTrazabilidaQuery,
  useGetFilesxfiltersMutation,
  useLazyGetFilesFiltersQuery,
  useLazyGetDownloadZipQuoleQuery,
  useLazyGetDownloadPdfQuoleQuery
} = trkgfileApiSlice;
